//export const SERVERURI = 'http://localhost';
//const SERVERURI = 'http://localhost'
//const SERVERURI = 'http://http://76.243.4.28/'
//export const SERVERURI = 'http://132.145.211.115';
export const SERVERURI = window.location.protocol + '//' + window.location.hostname;
export var SERVERURI_PORT = '';


const { REACT_APP_USESERVER } = process.env;
const { REACT_APP_MYPORT } = process.env;
export const { REACT_APP_BACKEND } = process.env;

// In Vercel, this ENV variable must be set to the backend Microservice Deployment
//REACT_APP_BACKEND=https://row-sessions-server.vercel.app

if (REACT_APP_BACKEND == null)
  SERVERURI_PORT = window.origin
else
  SERVERURI_PORT = REACT_APP_BACKEND

  console.log("Using Micro backend: " + SERVERURI_PORT)

/***   rowingschedule.cycrowing.org is the DNS name.  did this to know  to go to a diff URI , NOT just a diff port & same IP. */
/*** started with [npm run vercel] or above comment */
//if (SERVERURI.includes('vercel') || (SERVERURI.includes('rowingschedule')) || ((REACT_APP_USESERVER != null) && (REACT_APP_USESERVER.trim() === 'vercel'))) {
 // SERVERURI_PORT = 'https://row-sessions-server.vercel.app';
//}
/** started using [npm run start] (would be null) or [npm run oracle]*/
//if (((REACT_APP_USESERVER != null) && (REACT_APP_USESERVER.trim() === 'oracle'))) {
//  SERVERURI_PORT = 'http://129.213.176.138:3001';
//}
//var port = (REACT_APP_MYPORT != null) ? ':3001' : '';
/** if didn't start local with npm run oracle|vercel assume coming from servers and just go back to server on port 3001 */
//if (SERVERURI_PORT === '') {
//  SERVERURI_PORT = SERVERURI + ((window.location.protocol === 'http:') ? port : ':3002');
//}
//SERVERURI_PORT = REACT_APP_BACKEND;




