import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';


import Switch from '@mui/material/Switch';


export default function FormDialog(props) {
  //props should contain a default session if EDIT props.
  // 
  const [open, setOpen] = React.useState(false);
  const [showSatRec, setShowSatRec] = React.useState(props.properties.IncludeSaturdayRecRow)

  const handleClickOpen = () => {
    
    setOpen(true);
    
  };

  const handleClose = () => {
    setOpen(false);
    props.openHandler();
  };
  function handleAddOrEdit(jo) {
    
    // Code to handle login goes here

    props.acceptCallBack(jo)
    handleClose();
  
}

function normalizeDate(aDateString) {
  var d = new Date(aDateString.replace('.000Z', ''))
  d.setHours(0, 0, 0, 0)
  return d.toDateString()
}
const handleSwitchChange = (event) => {
  setShowSatRec(event.target.checked);
};

  return (
    <React.Fragment>
      
       <MenuItem onClick={handleClickOpen}>Admin Settings...</MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            //have to move the value to the JSON because the FORM won't show the value if its OFF
            formJson.IncludeSaturdayRecRow= (formJson.IncludeSaturdayRecRow!=null)&&formJson.IncludeSaturdayRecRow=='on'?'true':'false'
     //       const date = formJson.date;
            handleAddOrEdit(formJson);
          },
        }}
      >
        <DialogTitle>Admin Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit Defaults
          </DialogContentText>
          <TextField required  margin="dense" id="NumberOfDaysToShow"  label="Days to Show" name="NumberOfDaysToShow"  defaultValue={props.properties.NumberOfDaysToShow}  type="Number" fullWidth  variant="outlined" />
          <TextField required margin="dense" id="SaturdayDefaultStart" label="Saturday Training Row Start Time" name="SaturdayDefaultStart" type="text" defaultValue={props.properties.SaturdayDefaultStart}  fullWidth variant="outlined" />
          <TextField required margin="dense" id="SaturdayDescription" label="Saturday Training Row Description" name="SaturdayDescription" type="text" defaultValue={props.properties.SaturdayDescription}  fullWidth variant="outlined" />
          
          <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px', padding: '0 0px' }}>
          <FormControlLabel sx={{ '& .MuiTypography-root': { fontSize: '0.8rem' } }} name="IncludeSaturdayRecRow" label="Show Saturday Second Row" labelPlacement="start"   control={<Switch onChange={handleSwitchChange} defaultChecked={props.properties.IncludeSaturdayRecRow} />} />
          </Box>
          <TextField  disabled = {!showSatRec}   required margin="dense" id="SaturdayRecRowStart"  label="Saturday Second Row Start Time" name="SaturdayRecRowStart"  defaultValue={props.properties.SaturdayRecRowStart}  type="text" fullWidth  variant="outlined" />
          <TextField  disabled = {!showSatRec}  required margin="dense" id="SaturdayRecDescription" label="Saturday Second Row Description" name="SaturdayRecDescription" type="text" defaultValue={props.properties.SaturdayRecDescription}  fullWidth variant="outlined" />
      
          <TextField required  margin="dense" id="SundayDefaultStart"  label="Sunday Row Start Time" name="SundayDefaultStart" defaultValue={props.properties.SundayDefaultStart}    type="text" fullWidth  variant="outlined" />
          <TextField required margin="dense" id="SundayDescription" label="Sunday Row Description"   name="SundayDescription" type="text" defaultValue={props.properties.SundayDescription}  fullWidth variant="outlined" />
          <TextField datatype='String' required margin="dense" id="OpenRowDescription"  label="Weekday Open Row Description" name="OpenRowDescription"  type="text" defaultValue={props.properties.OpenRowDescription}  fullWidth variant="outlined" />
          <TextField required  margin="dense" id="MaxSeats"  label="Maximum Seats (1000 hides denominator)" name="MaxSeats"  defaultValue={props.properties.MaxSeats}  type="Number" fullWidth  variant="outlined" />
          <TextField  sx={{width:'0px'}}  required margin="dense" id="_id"  name="_id" hiddenLabel={true} type="hidden" defaultValue={props.properties._id}  fullWidth variant="standard" />          
          <TextField sx={{width:'0px'}}  required margin="dense"  hiddenLabel={true} type="hidden"   id="Name"  name="Name" defaultValue={props.properties.Name} fullWidth variant="standard" />
        
                  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" >Accept</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}