import * as React from "react";

// importing material UI components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import BasicMenu from './components/BasicMenu';

import { useState } from "react";
import LoginFormDialog from "./components/LoginFormDialog"



export default function Header(props) {

    function setUser(jo) {
        props.setUserInParent(jo);
    }

    function logoutParent() {
        props.logoutParent();
    }
    function handleAccept(jo){
        props.acceptCallBack(jo)
    }
    
    return (
        <AppBar position="sticky">
            <Toolbar>
                {/*Inside the IconButton, we 
                    can render various icons*/}
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    {/*This is a simple Menu 
                      Icon wrapped in Icon */}
                    <BasicMenu setShowToday={props.setShowToday}  isAdmin={props.isAdmin} properties={props.properties} acceptCallBack={handleAccept} logoutParent={logoutParent} />
                </IconButton>
                {/* The Typography component applies 
                     default font weights and sizes */}

                <Typography
                    variant="h6"
                    component="div"
                    align="center"
                    sx={{ fontSize:'1.1rem' ,flexGrow: 1 }}
                >
                    CYC Rowing Schedule
                </Typography>
                <LoginFormDialog setUserInParent={setUser} currentUser={props.currentUser} ></LoginFormDialog>

            </Toolbar>

        </AppBar>
    );
}